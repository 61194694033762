<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <template v-if="$options.components['bill-payment-category']">
          <bill-payment-category />
        </template>
      </b-col>
      <b-col md="9">
        <div class="viewplan-maindv">
          <b-tabs pills>
            <b-tab title="Recomended Plans" active>
              <b-card-text
                class="mt-4"
                v-for="(
                  recomendedPlan, index
                ) in recomendedData.recomendedPlans"
                :key="index"
              >
                <ul class="list-unstyled">
                  <b-media tag="li" class="border-bottom pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">
                        Validity : {{ recomendedPlan.validity }}
                      </h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                        @click="toamount(recomendedPlan.amount)"
                      >
                        &#x20B9; {{ recomendedPlan.amount }}
                      </button>
                    </div>
                    <p class="mb-0">
                      <small> {{ recomendedPlan.planDescription }}</small>
                    </p>
                  </b-media>
                </ul>
              </b-card-text>
            </b-tab>
            <b-tab title="All Plans" active>
              <b-card-text
                class="mt-4"
                v-for="(
                  allRechargePlans, index
                ) in availablePlans.allRechargePlans"
                :key="index"
              >
                <ul class="list-unstyled">
                  <b-media tag="li" class="border-bottom pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">
                        Validity :{{ allRechargePlans.validity }}
                      </h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                        @click="toamount(allRechargePlans.amount)"
                      >
                        &#x20B9; {{ allRechargePlans.amount }}
                      </button>
                    </div>
                    <p class="mb-0">
                      <small>{{ allRechargePlans.planDescription }}</small>
                    </p>
                  </b-media>
                  <!-- <b-media tag="li" class="border-bottom pt-3 pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">Validity : 24 day's</h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                      >
                        &#x20B9; 99
                      </button>
                    </div>
                    <p class="mb-0">
                      <small
                        >For Validity Extension / Plan Migration customers only.
                        Truly Unlimited Local/STD/roaming calls incl. Mumbai and
                        Delhi + Unlimited Data speed reduced to 80kbps after
                        2GB/day + 100 SMS/day + Zing(18days).Freebies valid for
                        18 days. Applicable only for PV_99.</small
                      >
                    </p>
                  </b-media> -->
                  <!-- <b-media tag="li" class="border-bottom pt-3 pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">Validity : 24 day's</h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                      >
                        &#x20B9; 99
                      </button>
                    </div>
                    <p class="mb-0">
                      <small
                        >For Validity Extension / Plan Migration customers only.
                        Truly Unlimited Local/STD/roaming calls incl. Mumbai and
                        Delhi + Unlimited Data speed reduced to 80kbps after
                        2GB/day + 100 SMS/day + Zing(18days).Freebies valid for
                        18 days. Applicable only for PV_99.</small
                      >
                    </p>
                  </b-media> -->
                </ul>
              </b-card-text>
            </b-tab>
            <!-- <b-tab title="Topup">
              <b-card-text class="mt-4">
                <ul class="list-unstyled">
                  <b-media tag="li" class="border-bottom pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">Validity : 24 day's</h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                      >
                        &#x20B9; 99
                      </button>
                    </div>
                    <p class="mb-0">
                      <small
                        >For Validity Extension / Plan Migration customers only.
                        Truly Unlimited Local/STD/roaming calls incl. Mumbai and
                        Delhi + Unlimited Data speed reduced to 80kbps after
                        2GB/day + 100 SMS/day + Zing(18days).Freebies valid for
                        18 days. Applicable only for PV_99.</small
                      >
                    </p>
                  </b-media>
                </ul>
              </b-card-text>
            </b-tab> -->
            <!-- <b-tab title="Full Talktime">
              <b-card-text class="mt-4">
                <ul class="list-unstyled">
                  <b-media tag="li" class="border-bottom pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">Validity : 24 day's</h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                      >
                        &#x20B9; 99
                      </button>
                    </div>
                    <p class="mb-0">
                      <small
                        >For Validity Extension / Plan Migration customers only.
                        Truly Unlimited Local/STD/roaming calls incl. Mumbai and
                        Delhi + Unlimited Data speed reduced to 80kbps after
                        2GB/day + 100 SMS/day + Zing(18days).Freebies valid for
                        18 days. Applicable only for PV_99.</small
                      >
                    </p>
                  </b-media>
                </ul>
              </b-card-text>
            </b-tab> -->
            <!-- <b-tab title="Unlimited Plans">
              <b-card-text class="mt-4">
                <ul class="list-unstyled">
                  <b-media tag="li" class="border-bottom pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">Validity : 24 day's</h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                      >
                        &#x20B9; 99
                      </button>
                    </div>
                    <p class="mb-0">
                      <small
                        >For Validity Extension / Plan Migration customers only.
                        Truly Unlimited Local/STD/roaming calls incl. Mumbai and
                        Delhi + Unlimited Data speed reduced to 80kbps after
                        2GB/day + 100 SMS/day + Zing(18days).Freebies valid for
                        18 days. Applicable only for PV_99.</small
                      >
                    </p>
                  </b-media>
                </ul>
              </b-card-text>
            </b-tab> -->

            <!-- <b-tab title="Roaming">
              <b-card-text class="mt-4">
                <ul class="list-unstyled">
                  <b-media tag="li" class="border-bottom pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">Validity : 24 day's</h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                      >
                        &#x20B9; 99
                      </button>
                    </div>
                    <p class="mb-0">
                      <small
                        >For Validity Extension / Plan Migration customers only.
                        Truly Unlimited Local/STD/roaming calls incl. Mumbai and
                        Delhi + Unlimited Data speed reduced to 80kbps after
                        2GB/day + 100 SMS/day + Zing(18days).Freebies valid for
                        18 days. Applicable only for PV_99.</small
                      >
                    </p>
                  </b-media>
                </ul>
              </b-card-text>
            </b-tab> -->

            <!-- <b-tab title="3G/4G">
              <b-card-text class="mt-4">
                <ul class="list-unstyled">
                  <b-media tag="li" class="border-bottom pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">Validity : 24 day's</h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                      >
                        &#x20B9; 99
                      </button>
                    </div>
                    <p class="mb-0">
                      <small
                        >For Validity Extension / Plan Migration customers only.
                        Truly Unlimited Local/STD/roaming calls incl. Mumbai and
                        Delhi + Unlimited Data speed reduced to 80kbps after
                        2GB/day + 100 SMS/day + Zing(18days).Freebies valid for
                        18 days. Applicable only for PV_99.</small
                      >
                    </p>
                  </b-media>
                </ul>
              </b-card-text>
            </b-tab> -->

            <!-- <b-tab title="Rate Cutters">
              <b-card-text class="mt-4">
                <ul class="list-unstyled">
                  <b-media tag="li" class="border-bottom pb-3">
                    <div class="d-flex justify-content-between mb-2">
                      <h5 class="mt-0 mb-1">Validity : 24 day's</h5>
                      <button
                        type="button"
                        class="btn btn-outline-primary paynow-btn"
                      >
                        &#x20B9; 99
                      </button>
                    </div>
                    <p class="mb-0">
                      <small
                        >For Validity Extension / Plan Migration customers only.
                        Truly Unlimited Local/STD/roaming calls incl. Mumbai and
                        Delhi + Unlimited Data speed reduced to 80kbps after
                        2GB/day + 100 SMS/day + Zing(18days).Freebies valid for
                        18 days. Applicable only for PV_99.</small
                      >
                    </p>
                  </b-media>
                </ul>
              </b-card-text>
            </b-tab> -->
          </b-tabs>
        </div>
        <b-col md="12" class="latest-offer-div">
          <h4 class="mb-4 mt-3">Latest Offers</h4>
          <template v-if="$options.components['bill-payment-latest-offers']">
            <bill-payment-latest-offers />
          </template>
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      recomendedData: (state) => state.billPayment.recomendedData,
      availablePlans: (state) => state.billPayment.availablePlans,
    }),
  },
  methods: {
    toamount(rechargeAmount) {
      this.$router.push({
        name: "rechargeAmount",
        params: {
          amount: rechargeAmount,
          operator: this.$route.params.operator,
          circle: this.$route.params.circle,
          mobileNumber: this.$route.params.mobileNumber,
        },
      });
    },
  },
};
</script>
<style scoped>
.viewplan-maindv {
  border: 1px solid #f5f5f5;
  border-radius: 6px;
}
.btn-outline-primary.paynow-btn {
  color: #272727;
  border-color: #272727;
  padding: 2px 20px;
  font-size: 14px;
  line-height: 1.4;
}
.btn-outline-primary.paynow-btn:hover {
  color: #fff;
  background-color: #272727;
}
</style>